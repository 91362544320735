dl, h3, menu, ol, p, pre, ul {
  margin: 1em 0;
}
/*article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
    display: block;
}*/

header, section {
  display: block;
  width: 100%;
}

[hidden] {
  display: none;
}
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

button, html, input, select, textarea {
  font-family: sans-serif;
}

body, figure, form {
  margin: 0;
  background-color: #000;
}

body, #root {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
}

.Footer {
  box-sizing: border-box;
  width: 100%;
  display: block;
  justify-content: center;
  align-items: baseline;
  padding: 0.5em;
  margin-top: 2em;
  background-color: #000;
  color: #fff;
}

.Footer p {
  color: rgba(255, 255, 255, 0.75);
}

#footer {
    position: relative;
    margin: 0 auto;
    text-align: center;
  font: 14px/20px Sarabun-SemiBold, Arial, Verdana, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #979797;
    padding: 10px 0;
}
#footer span {
    font-size: 14px;
    margin: 0 20px;
}
#footer a {
  font: 14px/20px Sarabun-SemiBold, Arial, Verdana, sans-serif;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
  display: inline-block;
  margin: 0 20px;
}

#heading {
  padding-top: 100px;
  text-align: center;
}

#heading h2 {
  margin: 40px 0;
}

#credits-gallery p {
  color: #fff;
  margin: 0;
  font: 16px/23px Sarabun-SemiBold, Arial, Verdana, sans-serif;
}

#credits-gallery {
  width: calc(100% - 100px);
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 50px;
  color: #666;
  font: 16px/23px Sarabun-SemiBold, Arial, Verdana, sans-serif;
}

.media img {
  width: 100%;
  aspect-ratio: 1.5;
  object-fit: cover;
  text-align: center;
}

.author {
  margin-bottom: 0.5em;
  font: 18px/24px Sarabun-SemiBold, Arial, Verdana, sans-serif;
}

#support-gallery {
  width: calc(100% - 100px);
  max-width: 1200px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 50px;
  color: #666;
  font: 16px/23px Sarabun-SemiBold, Arial, Verdana, sans-serif;
  text-align: center;
}

.support img {
  height: 300px;
}

/*  src/Title.css  */
@font-face {
  font-family: Asap-Regular;
  src: url(../fonts/Asap-Regular.ttf) format("truetype");
  font-style: normal;
  font-weight: 400;
  font-display: block;
}
@font-face {
  font-family: Asap-Medium;
  src: url(../fonts/Asap-Medium.ttf) format("truetype");
  font-style: normal;
  font-weight: 400;
  font-display: block;
}
@font-face {
  font-family: Asap-SemiBold;
  src: url(../fonts/Asap-SemiBold.ttf) format("truetype");
  font-style: normal;
  font-weight: 400;
  font-display: block;
}

@font-face {
  font-family: Sarabun-Regular;
  src: url(../fonts/Sarabun-Regular.ttf) format("truetype");
  font-style: normal;
  font-weight: 400;
  font-display: block;
}
@font-face {
  font-family: Sarabun-Medium;
  src: url(../fonts/Sarabun-Medium.ttf) format("truetype");
  font-style: normal;
  font-weight: 400;
  font-display: block;
}
@font-face {
  font-family: Sarabun-SemiBold;
  src: url(../fonts/Sarabun-SemiBold.ttf) format("truetype");
  font-style: normal;
  font-weight: 400;
  font-display: block;
}
@font-face {
  font-family: Sarabun-Italic;
  src: url(../fonts/Sarabun-Italic.ttf) format("truetype");
  font-style: normal;
  font-weight: 400;
  font-display: block;
}

a:focus {
  outline: dotted thin;
}
a:active, a:hover {
  outline: 0;
}
a:active, a:link, a:visited {
  color: #fff;
  text-decoration: none;
}
a {
  font: 16px/24px Asap-Medium, Arial, Verdana, sans-serif;
  font-weight: 700;
  font-style: normal;
}
a:active {
  outline: 0;
}
a:not(.hover-none):hover {
  color: #ccc;
  text-decoration: none;
}

#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  z-index: 100;
}

.header-inner {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1400px;
  margin: 0 auto;
  text-align: left;
}

#logo {
  position: absolute;
  top: 35px;
  left: 80px;
  width: auto;
  height: 30px;
}

#logo img, #logo svg {
  display: block;
  width: auto;
  height: 100%;
}

#logo .fill-white {
  fill: #fff;
}

#navigation {
  display: inline-block;
  left: 200px;
}

#navigation, #navigation-right {
  position: absolute;
  top: 35px;
  height: 30px;
}

.nav-links {
  position: relative;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.nav-links li {
  position: relative;
  display: inline-block;
  margin: 0 25px;
}
.nav-links li a {
  display: block;
  line-height: 1.4;
}
.nav-links a {
  position: relative;
  font: 17px/33px Sarabun-SemiBold, Arial, Verdana, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #fff;
  text-transform: uppercase;
  -webkit-transition: color .25s ease;
  transition: color .25s ease;
}


#hamburger, #menu-navigation, .bar, .play-arrow {
  position: absolute;
}
#hamburger {
  display: none;
  top: 50%;
  right: 50px;
  width: 24px;
  height: 24px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 55;
  padding: 0 8px;
  border: 0;
  background: 0 0;
  appeareance: none;
  -webkit-transition: opacity .2s linear .1s;
  transition: opacity .2s linear .1s;
  cursor: pointer;
}
#hamburger:active, #hamburger:focus {
  outline: 0;
}
.bar {
  top: 5px;
  left: 0;
  background-color: #fff;
  width: 100%;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  height: 2px;
}
#menu, #menu-close {
  position: fixed;
  top: 0;
  height: 100%;
}
#bar2 {
  top: 11px;
}
#bar3 {
  top: 17px;
}

.bar, #menu, #menu-navigation {
 -webkit-transition-duration: .4s;
  transition-duration: .4s;
}

#menu {
  right: 0;
  width: 0px;
  z-index: 12;
}
#menu-background {
  -webkit-transform-origin: left top;
  transform-origin: left top;
  display: block;
  width: 100%;
  height: 120%;
  background-color: rgba(0, 0, 0, .8);
}
#menu-navigation {
  top: 75px;
  right: 50px;
  width: 200px;
}
#menu-navigation .nav-links li {
  display: block;
  margin: 0;
  text-align: right;
}
#menu-navigation .nav-links li.primary {
  display: none;
}
#menu-navigation .nav-links a {
  display: block;
  font: 16px/40px Sarabun-SemiBold, Arial, Verdana, sans-serif;
  font-weight: 400;
  font-weight: 500;
  font-style: normal;
  color: #fff;
  text-transform: uppercase;
  border-bottom: 1pt solid #333;
}


#wrapper, .background, .background-gradient, .background-optional, .knockout, .section, .section-inner {
  display: block;
  width: 100%;
}

#wrapper {
  overflow-x: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 1;
}

.btn .text, .section {
    position: relative;
}


#scroller {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  will-change: transform;
}

.background, .background-gradient, .background-optional, .knockout {
  position: absolute;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #333;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.background-gradient {
  background-image: linear-gradient(to right, rgba(0,0,0,1) 30%, rgba(0,0,0,0) 60%);
  background-color: rgba(0,0,0,0);
  opacity: 0.6;
  zorder: 1;
}

.background-optional {
  background-color: rgba(0,0,0,0);
  opacity: 0;
  zorder: 1;
}


.section {
  height: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
  position: relative;
  display: block;
}

.section--auto {
  height: auto;
  min-height: 100%;
}


.inner-left-bottom {
  position: absolute;
  bottom: 10%;
  left: 100px;
  text-align: left;
  max-width: 520px;
}
.inner-center-bottom {
  position: absolute;
  width: 80%;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  text-align: center;
}
.inner-right-bottom {
  position: absolute;
  bottom: 15%;
  right: 150px;
  text-align: left;
}
.inner-center-middle {
  position: absolute;
  width: 80%;
  bottom: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  text-align: center;
}

.indent {
  margin-right: -20px;
}

.inspiration {
  font: 20px/24px Sarabun-Italic, Arial, Verdana, sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.6;
  margin-bottom: 30px;
}


.inner-left-top {
  position: absolute;
  top: 15%;
  left: 100px;
  text-align: left;
  max-width: 520px;
}

.video-container {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  text-align: center;
  overflow: hidden;
  width: 100%;
  padding-bottom: 56.25%;
  clear: both;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

table {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 35px;
    margin-right: -20px;
}
table td {
    font: 16px/18px Sarabun-Medium, Arial, Verdana, sans-serif;
    text-align: left;
    color: #fff;
    padding: 20px 0;
    border-bottom: 1pt solid rgba(255, 255, 255, .3);
}
table.data td {
    text-align: right;
}
table.data td:first-child {
    font: 16px/18px Sarabun-SemiBold, Arial, Verdana, sans-serif;
    text-align: left;
}
table.data td span {
    color: #868686;
}

table.data a {
  font-weight: 400;
}


h1, h2, h3 {
  position: relative;
  text-transform: uppercase;
  color: #fff;
}
h1 {
  font: 80px/70px Sarabun-SemiBold, Arial, Verdana, sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: auto;
}
h2 {
  font: 46px/50px Sarabun-SemiBold, Verdana, sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: auto;
}
h3 {
  font: 30px/34px Sarabun-SemiBold, Verdana, sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: auto;
}
h4 {
  position: relative;
  font: 20px/38px Sarabun-Regular, Arial, Verdana, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #fff;
  margin: auto;
}

p {
  position: relative;
  font: 18px/23px Sarabun-Regular, Arial, Verdana, sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.6;
  color: #fff;
}

p.team {
  font: 18px/24px Sarabun-SemiBold, Arial, Verdana, sans-serif;
  padding-top:5px;
  margin-bottom:-15px;
}

span.team {
  font: 18px/24px Sarabun-SemiBold, Arial, Verdana, sans-serif;
}

@media screen and (min-width: 1200px){
#credits-gallery, #support-gallery {
  width: calc(100% - 100px);
  grid-template-columns: 1fr 1fr 1fr;
}
}

@media screen and (max-width: 1200px){
.inner-left-top {
  max-width: 520px;
  left: 50px;
}

.inner-left-bottom {
  max-width: 520px;
  left: 50px;
  bottom: 5%;
}

.background-gradient {
  background-image: linear-gradient(to right, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 80%);
}

#credits-gallery, #support-gallery {
  width: calc(100% - 50px);
  grid-template-columns: 1fr 1fr;
}
}

@media screen and (min-width: 960px){
#menu {
  display: none;
}
}

@media screen and (max-width: 960px){
#navigation {
  display: none;
}

#hamburger {
  display: block;
}

#logo {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  width: 100px;
  height: auto;
/*   margin-top: -5px; */
/*   margin-left: 15px; */
/*   z-index: 55; */
}

h1 {
  font: 60px/70px Sarabun-SemiBold, Arial, Verdana, sans-serif;
}
}

@media screen and (max-width: 690px){
#support-gallery {
  width: 90%;
  grid-template-columns: 1fr;
}

.background-gradient, .background-optional {
  background-image: linear-gradient(to right, rgba(0,0,0,1) 100%, rgba(0,0,0,0) 100%);
  opacity: 0.6;
}
}

@media screen and (max-width: 600px){
.background {
  height: 1200px;
}

.inner-left-top {
  width: auto;
  max-width: auto;
  right:20px;
  left:20px;
}

.inner-left-bottom {
  width: auto;
  max-width: auto;
  bottom: 5%;
  right:20px;
  left:20px;
}

h1 {
  font: 40px/50px Sarabun-SemiBold, Arial, Verdana, sans-serif;
}

#credits-gallery, #support-gallery {
  width: 90%;
  grid-template-columns: 1fr;
}
}

/* width:"35%", top:"20%", left:"100px", maxWidth:"600px" */
